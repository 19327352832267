<template lang="pug">
    .duvidasFrequentes

        b-modal(v-model='dialog.faleConosco' :can-cancel="[ 'x' ]")
            FaleConosco
        
        .py-64(v-if='waiting.duvidasFrequentes')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.duvidasFrequentes')

        .flex.justify-center.bg-medclub-blue-100.px-2.py-5(v-else class='md:pt-12 md:pb-8')
            .max-w-screen-lg.w-full.relative

                .absolute.left-0(style='top:-8px' v-if='! waiting.duvidasFrequentes')
                    a.text-3xl(@click='$router.go(-1)')
                        i.jam.jam-chevron-left
                        
                p.text-center.font-bold.text-medclub-blue-400 Dúvidas frequentes

                .w-full.my-8(v-if='medempresa')
                    .modulo.modulo_duvidas#duvidas
                        b-tabs(v-model="activeTab" v-if="waiting.duvidasFrequentes === false")
                            b-tab-item(:label="categoria.nm_categoria" v-for="categoria in categorias" :key="categoria.id" v-if="['MEDE', 'MEDG'].includes(categoria.ie_tipo)")
                                #accordion(v-for='(item, index) in list' :key="item.id")
                                    .card(v-if="item.cd_pergunta_categoria && item.cd_pergunta_categoria.id === categoria.id && ['MEDE', 'MEDG'].includes(item.ie_tipo)")
                                        .card-header(:id="`heading${index}`")
                                            h5.mb-0
                                                button.btn.collapsed(data-toggle="collapse" :data-target="`#collapse${index}`" aria-expanded="false" :aria-controls="`collapse${index}`")
                                                    | {{item.ds_pergunta}}
                                        .collapse(:id="`collapse${index}`" :aria-labelledby="`heading${index}`" data-parent="#accordion")
                                            .card-body
                                                div.whitespace-pre-wrap {{item.ds_resposta}}
                .w-full.my-8(v-if='!medempresa')
                    .modulo.modulo_duvidas#duvidas
                        b-tabs(v-model="activeTab" v-if="waiting.duvidasFrequentes === false")
                            b-tab-item(:label="categoria.nm_categoria" v-for="categoria in categorias" :key="categoria.id" v-if="['MEDP', 'MEDG'].includes(categoria.ie_tipo)")
                                #accordion(v-for='(item, index) in list' :key="item.id")
                                    .card(v-if="item.cd_pergunta_categoria && item.cd_pergunta_categoria.id === categoria.id && ['MEDP', 'MEDG'].includes(item.ie_tipo)")
                                        .card-header(:id="`heading${index}`")
                                            h5.mb-0
                                                button.btn.collapsed(data-toggle="collapse" :data-target="`#collapse${index}`" aria-expanded="false" :aria-controls="`collapse${index}`")
                                                    | {{item.ds_pergunta}}
                                        .collapse(:id="`collapse${index}`" :aria-labelledby="`heading${index}`" data-parent="#accordion")
                                            .card-body
                                                div.whitespace-pre-wrap {{item.ds_resposta}}
</template>

<script>
    import { Utils } from '../../middleware'
    import FaleConosco from './FaleConosco'
    export default {
        created () {
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            this.waiting.duvidasFrequentes = true
            Utils.getCategoriasPerguntas().then(response => {
                this.categorias = response.data

                Utils.getPerguntasFrequentes().then(response => {
                    this.waiting.duvidasFrequentes = false
                    if (response.status === 200) {
                        this.list = response.data
                        this.list.forEach(item => { item._trigger = false })
                    }
                })
            })
        },
        components: { FaleConosco },
        data () {
            return {
                list: [],
                categorias: [],
                waiting: { duvidasFrequentes: true },
                dialog: { faleConosco: false },
                activeTab: 0,
                medempresa: false,
            }
        }
    }
</script>